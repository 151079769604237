import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Dot } from 'app/components/Common/Dot'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Offer, Props as OfferProps } from 'app/components/OffersList/Offer'
import { useVocabularyData } from 'app/utils/vocabulary'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useState } from 'react'

export interface Props {
  languageCode: string
  offers: OfferProps[]
}

export const OtherOffers = memo(function OtherOffers({
  languageCode,
  offers,
}: Props) {
  if (offers.length < 1) {
    return null
  }

  const [currentSlide, setCurrentSlide] = useState(0)

  const [sliderRef, instanceRef] = useKeenSlider({
    loop: offers.length > 2 ? true : false,
    slides: {
      perView: 2,
      spacing: 60,
    },
    animationEnded(s) {
      setCurrentSlide(s.track.details.rel)
    },
    breakpoints: {
      '(max-width: 1199px)': {
        slides: {
          perView: 2.01,
          spacing: 20,
        },
      },
      '(max-width: 767px)': {
        slides: {
          perView: 1.01,
          spacing: 12,
        },
      },
    },
  })

  return (
    <Container>
      <Title>{useVocabularyData('other-offers', languageCode)}</Title>
      <Slider ref={sliderRef}>
        {offers.map((item, index) => (
          <Offer key={index} className="keen-slider__slide" {...item} />
        ))}
      </Slider>

      {offers.length > 1 ? (
        <Dots dial={2} row>
          {offers.map((_item, index) => (
            <Dot
              key={index}
              className={currentSlide === index ? 'active' : undefined}
              onClick={() => {
                instanceRef.current?.moveToIdx(index)
              }}
            />
          ))}
        </Dots>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.primaryDark};
  margin: 6.25rem auto 0;
  padding: 5.125rem 6.5625rem 4.125rem;

  @media (max-width: 1199px) {
    padding: 3.75rem 1.875rem;
  }
`
const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-size: 1.625rem;
  font-weight: 500;
  text-align: center;
  line-height: 2.8125rem;
  margin: 0 auto 4.25rem;

  @media (max-width: 1199px) {
    margin-bottom: 2.5rem;
  }

  @media (max-width: 767px) {
    margin-bottom: 1.875rem;
  }
`

const Slider = styled.div`
  display: flex;
  margin-top: 4.25rem;
  overflow: hidden;
  &[data-keen-slider-moves] {
    a {
      pointer-events: none;
    }
  }

  @media (max-width: 1199px) {
    overflow: visible;
    margin-top: 2.5rem;
  }
`

const Dots = styled(FlexBox)`
  margin-top: 3.625rem;
`
