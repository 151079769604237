import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'

export interface Props {
  availabilityURL?: string
  contactsPageURL?: string
  label?: string
  languageCode: string
  title?: string
}

export const BookingCTA = memo(function BookingCTA({
  availabilityURL,
  contactsPageURL,
  label,
  languageCode,
  title,
}: Props) {
  return (
    <Container>
      {label ? <Label>{label}</Label> : null}
      {title ? <Title>{title}</Title> : null}
      <Wrapper row dial={5} wrap>
        {availabilityURL ? (
          <AvailabilityCTA
            label={useVocabularyData('book-now', languageCode)}
            URL={availabilityURL}
          />
        ) : null}
        {contactsPageURL ? (
          <ContactsCTA
            label={useVocabularyData('request-info', languageCode)}
            URL={contactsPageURL}
            variant="outline"
          />
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.div`
  margin-top: 9.75rem;
  margin-bottom: 12.125rem;

  @media (max-width: 1199px) {
    margin: 7.5rem auto 9.375rem;
    padding: 0 1.875rem;
  }

  @media (max-width: 1023px) {
    margin: 5.625rem auto;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 3.875rem;
  max-width: 29.375rem;
  font-weight: 500;
  text-align: center;
  margin: 0 auto 2.5rem;

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.75rem;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.125rem;
  font-weight: 300;
  text-align: center;
  margin: 0 auto 1.875rem;

  @media (max-width: 1199px) {
    margin-bottom: 1.25rem;
  }
`
const Wrapper = styled(FlexBox)`
  margin: 0 auto;

  > a {
    height: 5rem;
    line-height: 5rem;

    &:first-of-type {
      margin-right: 0.75rem;
    }

    @media (max-width: 767px) {
      height: 3.75rem;
      line-height: 3.75rem;

      &:first-of-type {
        margin-right: 0;
      }
    }
  }
`

const AvailabilityCTA = styled(Button)`
  &:hover {
    background: ${({ theme }) => theme.colors.variants.primaryLight2};
  }

  @media (max-width: 767px) {
    width: 100%;
    margin: 0;
  }
`

const ContactsCTA = styled(Button)`
  &:hover {
    > span:before {
      background: ${({ theme }) => theme.colors.variants.primaryLight2};
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    margin: 0;
    margin-top: 0.75rem;
  }
`
