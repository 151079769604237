import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Line } from 'app/components/Common/Line'
import { Price, Star } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Includes } from './Includes'

export interface Props {
  description?: string
  includesList?: string[]
  includesTitle?: string
  languageCode: string
  pricefrom?: string
  title?: string
  validity?: string
}

export const OfferIntro = memo(function OfferIntro({
  description,
  includesList,
  includesTitle,
  pricefrom,
  title,
  validity,
}: Props) {
  if (!title) {
    return null
  }

  return (
    <Container tag="section" stretch row wrap>
      <LeftSide>
        {validity || pricefrom ? (
          <Head row wrap align="center">
            {validity ? (
              <Validity>
                <Star />
                {validity}
              </Validity>
            ) : null}
            {pricefrom ? (
              <PriceWrapper row align="center">
                <Price />
                <Pricefrom dangerouslySetInnerHTML={{ __html: pricefrom }} />
              </PriceWrapper>
            ) : null}
          </Head>
        ) : null}
        {title ? <Title dangerouslySetInnerHTML={{ __html: title }} /> : null}
        <Line className="line" variant="compact" />
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
      </LeftSide>
      <RightSide>
        <Includes include={includesList} title={includesTitle} />
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)``

const LeftSide = styled.div`
  width: 50%;
  padding: 4.0625rem 8.194vw 4.75rem 7.292vw;

  @media (max-width: 1199px) {
    padding: 3.125rem 1.875rem 3.75rem;
  }

  @media (max-width: 1023px) {
    width: 100%;
  }
`

const RightSide = styled.div`
  width: 50%;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 8.5rem 8.333vw;

  @media (max-width: 1199px) {
    padding: 5.625rem 1.875rem;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
`

const Head = styled(FlexBox)`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-size: 0.9375rem;
  line-height: 1rem;
  font-weight: 300;
  margin-bottom: 3.125rem;

  b {
    font-weight: 500;
  }

  svg {
    fill: ${({ theme }) => theme.colors.variants.primaryLight2};
    margin-right: 0.75rem;
    vertical-align: middle;
  }

  @media (max-width: 1199px) {
    > div,
    > span {
      width: 100%;
    }

    > div {
      margin-top: 1rem;
    }

    > span {
      margin-right: 0;
    }
  }
`

const Validity = styled.span`
  display: flex;
  align-items: center;
  margin-right: 3.75rem;
`

const PriceWrapper = styled(FlexBox)``

const Pricefrom = styled.span``

const Title = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-size: 40px;
  line-height: 3.875rem;
  font-weight: 500;

  b {
    font-style: italic;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.variants.primaryLight2};
  }

  @media (max-width: 1199px) {
    font-size: 1.875rem;
    line-height: 2.8125rem;
  }
`

const Description = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-weight: 300;
  font-size: 1.0625rem;
  line-height: 2rem;
  margin-top: 3.75rem;

  @media (max-width: 1199px) {
    font-size: 1rem;
    margin-top: 1.875rem;
  }
`
