import styled from '@emotion/styled'
import { Check } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  include?: string[]
  title?: string
}

export const Includes = memo(function Includes({ include, title }: Props) {
  if (!include) {
    return null
  }

  return (
    <Container>
      {title ? <Title>{title}</Title> : null}

      <List>
        {include.map((include: any, index) => (
          <Include key={index} row>
            <Check />
            {include.label}
          </Include>
        ))}
      </List>
    </Container>
  )
})

const Container = styled.div``

const Title = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  color: ${({ theme }) => theme.colors.variants.primaryLight2};
  font-size: 1.625rem;
  line-height: 2rem;
  font-weight: 500;
  margin-bottom: 2.5rem;
`

const List = styled.div`
  > div {
    font-family: ${({ theme }) => theme.fontFamily.paragraph};
    color: ${({ theme }) => theme.colors.variants.neutralDark2};
    font-size: 0.9375rem;
    line-height: 1.5625rem;
    font-weight: 300;
    margin-bottom: 1.875rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
  svg {
    fill: ${({ theme }) => theme.colors.variants.primaryLight2};
    height: 1.5625rem;
    margin-right: 0.6563rem;
  }
`

const Include = styled(FlexBox)``
