import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  className?: string
  onClick?: (e: any) => void
}

export const Dot = memo(function Dot({ className, onClick }: Props) {
  return <Container className={className} onClick={onClick} />
})

const Container = styled.div`
  width: 0.625rem;
  height: 0.625rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.3;
  margin: 0 0.8125rem;
  &.active {
    cursor: default;
    opacity: 1;
    pointer-events: none;
  }

  @media (max-width: 1199px) {
    margin: 0 0.5rem;
  }
`
